/* eslint-disable prefer-const */
import React from 'react';

const SpecializedEquipmentList = data => (
    <>
        <div className="yellowfull">
            <h2
                className="bluetxt"
                dangerouslySetInnerHTML={{
                    __html: data.eqTitle,
                }}
            />
        </div>

        {data.eqList.map((equipment, i) => {
            let orderNo = i + 1;
            if (orderNo % 2 === 0) {
                return (
                    <section
                        className="whywelist page-section"
                        key={`order_no${orderNo}`}
                    >
                        <div className="container flexwrap whywewhitelist">
                            <div className="whylistlft flexbox todwn">
                                <div className="equipstarimglft">
                                    <img
                                        src={
                                            equipment.equipment_image.localFile
                                                .childImageSharp.fluid.src
                                        }
                                        alt="why 2"
                                    />
                                    <img
                                        className="starrgt"
                                        src={
                                            equipment.equipment_star_image
                                                .localFile.childImageSharp.fluid
                                                .src
                                        }
                                        alt="star 2"
                                    />
                                </div>
                            </div>

                            <div className="whylistrgt flexbox whylisttxt toup">
                                <h2
                                    className="bluetxt"
                                    dangerouslySetInnerHTML={{
                                        __html: equipment.equipment_name,
                                    }}
                                />
                                <h4
                                    dangerouslySetInnerHTML={{
                                        __html: equipment.equipment_description,
                                    }}
                                />
                                <h2 className="bluetxt">This Encourages:</h2>
                                <h4
                                    className="plist"
                                    dangerouslySetInnerHTML={{
                                        __html: equipment.enquipment_encourages,
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                );
            }
            return (
                <section
                    className="whywelist page-section bg-primary text-white"
                    key={`order_no${orderNo}`}
                >
                    <div className="container flexwrap whywebluelist">
                        <div className="whylistlft flexbox whylisttxt">
                            <h2
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html: equipment.equipment_name,
                                }}
                            />
                            <h4
                                dangerouslySetInnerHTML={{
                                    __html: equipment.equipment_description,
                                }}
                            />
                            <h2 className="yellowtxt encourttl">
                                This Encourages:
                            </h2>
                            <h4
                                className="plist"
                                dangerouslySetInnerHTML={{
                                    __html: equipment.enquipment_encourages,
                                }}
                            />
                        </div>

                        <div className="whylistrgt flexbox">
                            <div className="equipstarimgrgt">
                                <img
                                    className="whiteborderimg"
                                    src={
                                        equipment.equipment_image.localFile
                                            .childImageSharp.fluid.src
                                    }
                                    alt="Why 1"
                                />
                                <img
                                    className="starlft"
                                    src={
                                        equipment.equipment_star_image.localFile
                                            .childImageSharp.fluid.src
                                    }
                                    alt="Star 1"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            );
        })}
    </>
);
export default SpecializedEquipmentList;
