import React from 'react';
import WhyWeImage from '../../images/whyweimg.jpg';

const WhyWe = data => (
    <section className="page-section smallestwdt" id="aboutsliderdiv">
        <div className="container">
            <img
                src={data.featImage.localFile.childImageSharp.fluid.src}
                alt="Why We"
                className="img_full_width"
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: data.content,
                }}
            />
        </div>
    </section>
);

export default WhyWe;
